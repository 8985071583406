.App {
  text-align: center;
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

a {
  color: #007bff;
  text-decoration: none !important;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3 !important;
  text-decoration: underline !important;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
.captureButton:hover{
  color: rgb(255, 255, 255) !important;
  text-decoration: none !important;
}
.tableNameLinkColor{
  color: #035297;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.headerSelect {
  font-size: 1.5rem !important;
  color: #002F87 !important;
}

.headerSection {
  width: 50%;
}

.campaignDropdown{
  width: 50% !important;
}

.loginSection{
  width: 20%;
}

.editLeadModal {
  height: 75vh;
  overflow-y: auto;
}

.grecaptcha-badge{
  bottom: 40px !important;
}

@media (max-width: 767px) {
  /* Your CSS styles for window width less than 768px go here */
  .campaignDropdown{
    width: 70% !important;
    margin: 0 auto;
  }
  .loginSection{
    width: 80%;
  }
  .editLeadModal{
    overflow-y: auto;
  }
  .headerSection {
    width: 100%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 60px;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.ProductTab{
  height: 50px;
}

@font-face {
  font-family: "Verlag Bold";
  src: url("../public/font/Verlag-Bold.ttf");
}
.verlag-bold {
  font-family: "Verlag Bold", serif;
}

select > option {
  font-size: 12px;
}

@media (min-width: 767px) {
  /* Your CSS styles for window width less than 768px go here */
  select > option {
    font-size: 18px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
